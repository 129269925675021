import React from 'react';
import Form from './Form';
import { FormModel } from '../models/FormModel';
import { OnFormChange, OnFormReady } from '../types/formHandlers';

export type FormViewProps = {
    locale: string;
    form: FormModel;
    onFormReady?: OnFormReady;
    onFormChange?: OnFormChange;
    className?: string;
    multiLang?: boolean;
};

const FormView = (props: FormViewProps): JSX.Element => {
    const { form, onFormReady, onFormChange, locale, className, multiLang } = props;
    return (
        <Form
            form={form}
            onFormReady={onFormReady}
            readOnly={true}
            onFormChange={onFormChange}
            locale={locale}
            lang={locale}
            className={className}
            multiLang={multiLang}
        />
    );
};

export default FormView;
